export const pricingPlans = [
    {
        plan: 'Free',
        description:
            'Personal Kit — includes basic features and <span>300 monthly submissions</span>.',
        monthlyPrice: 0,
        save: 38,
        yearlyPrice: 0,
        planType: 'basic',
        apiCalls: 10000,
        storageLimit: 1,
        formLimit: 1,
        monthlyPlanId: process.env.GATSBY_MONTHLY_PERSONAL,
        yearlyPlanId: process.env.GATSBY_YEARLY_PERSONAL,
        features: [
            '1 endpoint',
            '300 submissions per month',
            '1GB file storage',
            'Branding',
            'API rate limiting',
        ],
    },
    {
        plan: 'Personal',
        description:
            'Standard Kit — includes core features and <span>10,000 monthly submissions</span>.',
        monthlyPrice: 12,
        save: 38,
        yearlyPrice: 6,
        planType: 'personal',
        apiCalls: 10000,
        storageLimit: 5,
        formLimit: 5,
        monthlyPlanId: process.env.GATSBY_MONTHLY_PERSONAL,
        yearlyPlanId: process.env.GATSBY_YEARLY_PERSONAL,
        features: [
            '5 endpoints',
            '10,000 submissions/month',
            '5GB file storage',
            'Email Notification',
            'API access',
            'No Rate Limiting',
        ],
    },
    {
        plan: 'Start-up',
        description:
            'Smart Option — complete suite for building products and <span>50,000 monthly submissions</span>.',
        monthlyPrice: 29,
        save: 138,
        yearlyPrice: 24,
        planType: 'startup',
        apiCalls: 50000,
        storageLimit: 15,
        formLimit: 20,
        monthlyPlanId: process.env.GATSBY_MONTHLY_STARTUP,
        yearlyPlanId: process.env.GATSBY_YEARLY_STARTUP,
        features: [
            '20 endpoint',
            '50,000 submissions/month',
            '15GB file storage',
            'Email Notification',
            'API access',
            'No Rate Limiting',
        ],
    },
    {
        plan: 'Lifetime',
        description:
            'For Early Supporters — For lifetime <span>10,000 monthly submissions</span>.',
        monthlyPrice: 29,
        oneTimePrice: 199,
        save: 98,
        yearlyPrice: 14,
        planType: 'lifetime',
        apiCalls: 10000,
        storageLimit: 10,
        formLimit: 10,
        planId: process.env.GATSBY_LIFETIME_PLAN,
        monthlyPlanId: 'price_1KcAOIBjfZrWT7wHLld83gja',
        yearlyPlanId: 'price_1KcAOIBjfZrWT7wHKqMEUXbL',
        features: [
            '10 endpoint',
            '10,000 submissions/month',
            '10GB file storage',
            'Email Notification',
            'API access',
            'No Rate Limiting',
        ],
    },
];
